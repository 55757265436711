// ./src/pages/AdmissionRequirements/AdmissionProfile.js

import React, { useEffect, useState, useCallback } from 'react';
import {
  Container,
  Row,
  Col,
  Spinner,
  Button,
  Card,
  ListGroup,
  Alert,
} from 'react-bootstrap';
import LoggedNavbar from './components/LoggedNavbar';
import styled, { keyframes } from 'styled-components';
import {
  FaUser,
  FaCalendarAlt,
  FaPhone,
  FaHome,
  FaEdit,
  FaDownload,
} from 'react-icons/fa';
import { jsPDF } from 'jspdf';

// Keyframes for animations
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

// Styled Components
const Styles = styled.div`
  .profile-page {
    font-family: 'Roboto', sans-serif;
    background: linear-gradient(135deg, #f0f4f8, #d9e2ec);
    min-height: 100vh;
    padding: 60px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profile-content {
    animation: ${fadeIn} 1s ease-out;
  }

  .profile-header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    background: #ffffff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
    }

    .profile-photo {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      object-fit: cover;
      border: 5px solid #3b82f6;
      margin-right: 30px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s;

      &:hover {
        transform: scale(1.05);
      }
    }

    .profile-info {
      h2 {
        font-size: 32px;
        color: #1e293b;
        margin-bottom: 10px;
        font-weight: 700;
      }

      p {
        font-size: 18px;
        color: #4b5563;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .details-section {
    margin-bottom: 30px;

    h3 {
      font-size: 24px;
      color: #1e293b;
      margin-bottom: 20px;
      border-bottom: 2px solid #d1d5db;
      padding-bottom: 10px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 50px;
        height: 4px;
        background: #3b82f6;
        bottom: 0;
        left: 0;
        border-radius: 2px;
      }
    }

    .detail-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      padding: 10px;
      background: #f9fafb;
      border-radius: 10px;
      transition: background 0.3s;

      &:hover {
        background: #e2e8f0;
      }

      .icon {
        color: #3b82f6;
        margin-right: 15px;
        font-size: 20px;
        min-width: 25px;
      }

      .label {
        font-weight: bold;
        color: #374151;
        width: 200px;
      }

      .value {
        color: #6b7280;
        word-break: break-word;
      }
    }
  }

  .actions-section {
    text-align: center;

    .edit-button,
    .download-button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: #10b981;
      color: #ffffff;
      padding: 12px 30px;
      border-radius: 50px;
      text-decoration: none;
      font-size: 16px;
      font-weight: 600;
      transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
      border: none;
      cursor: pointer;
      gap: 10px;
      margin: 10px;

      &:hover {
        background: #059669;
        transform: translateY(-2px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      }

      &:disabled {
        background: #9ca3af;
        cursor: not-allowed;
        box-shadow: none;
      }
    }

    .download-button {
      background: #3b82f6;

      &:hover {
        background: #2563eb;
      }
    }
  }

  .loading-spinner {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;

    .spinner {
      width: 80px;
      height: 80px;
    }

    .loading-text {
      margin-top: 10px;
      font-size: 18px;
      color: #1e293b;
    }
  }

  .error-message {
    color: #b91c1c;
    background: #fee2e2;
    padding: 25px 30px;
    border-radius: 12px;
    margin-bottom: 25px;
    border: 1px solid #fca5a5;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    .profile-header {
      flex-direction: column;
      align-items: center;

      .profile-photo {
        margin-right: 0;
        margin-bottom: 20px;
      }

      .profile-info {
        text-align: center;
      }

      .detail-item {
        flex-direction: column;
        align-items: flex-start;

        .label {
          width: 100%;
          margin-bottom: 5px;
        }
      }
    }
  }
`;

function AdmissionProfile() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch user data from backend
  const fetchUserData = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('You are not authenticated. Please log in.');
      setLoading(false);
      return;
    }

    const url = `${process.env.REACT_APP_BASE_URL || 'https://api-pil.site/'}api/auth/user`; // Adjust the URL if different

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        cache: 'no-store',
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }

      const data = await response.json();
      setUserData(data.userData);
    } catch (err) {
      console.error('Error:', err);
      setError('There was an issue fetching your profile data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  // Function to handle profile data download (as PDF)
  const handleDownloadProfile = () => {
    if (!userData) return;

    const doc = new jsPDF();

    doc.setFontSize(22);
    doc.text('Admission Profile', 14, 22);

    doc.setFontSize(12);
    doc.setTextColor(40);
    let y = 32;

    const addText = (label, value) => {
      doc.text(`${label}:`, 14, y);
      doc.text(`${value}`, 60, y);
      y += 10;
    };

    addText('Name', userData.student_name);
    addText('Email', userData.email);
    addText('Class', userData.class);
    addText(
      'Date of Birth',
      `${userData.dob.day}-${userData.dob.month}-${userData.dob.year} (${userData.dob.in_words})`
    );
    addText("Father's Name", userData.father_name || 'N/A');
    addText("Mother's Name", userData.mother_name || 'N/A');
    addText('Blood Group', userData.blood_group || 'N/A');
    addText("Father's Contact", userData.father_contact || 'N/A');
    addText("Mother's Contact", userData.mother_contact || 'N/A');
    addText('PEN No', userData.pen_no || 'N/A');
    addText(
      'Residence',
      `${userData.residence || 'N/A'}, ${userData.village || 'N/A'}, ${userData.tehsil || 'N/A'}, ${userData.district || 'N/A'}`
    );
    addText('Sibling Studying', userData.sibling_studying ? 'Yes' : 'No');

    if (userData.sibling_studying) {
      addText("Sibling Name", userData.sibling_details?.name || 'N/A');
      addText("Sibling Class", userData.sibling_details?.class || 'N/A');
    }

    addText('Monthly Income', userData.monthly_income || 'N/A');

    doc.save(`${userData.student_name.replace(/\s+/g, '_')}_profile.pdf`);
  };

  // Function to handle profile edit (optional)
  const handleEditProfile = () => {
    // Redirect to edit profile page or open a modal
    // Implement as per your application's requirements
    alert('Edit profile functionality is not implemented yet.');
  };

  if (loading) {
    return (
      <>
        <LoggedNavbar />
        <Styles>
          <div className="profile-page">
            <Container>
              <Row className="justify-content-center">
                <Col lg={8} md={10} sm={12}>
                  <div className="profile-content">
                    <div className="loading-spinner">
                      <Spinner animation="border" variant="primary" className="spinner" />
                      <span className="loading-text">Loading your profile...</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Styles>
      </>
    );
  }

  if (error) {
    return (
      <>
        <LoggedNavbar />
        <Styles>
          <div className="profile-page">
            <Container>
              <Row className="justify-content-center">
                <Col lg={8} md={10} sm={12}>
                  <div className="profile-content">
                    <Alert variant="danger" className="error-message">
                      {error}
                    </Alert>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Styles>
      </>
    );
  }

  return (
    <>
      <LoggedNavbar />
      <Styles>
        <div className="profile-page">
          <Container>
            <Row className="justify-content-center">
              <Col lg={10} md={12}>
                <Card className="profile-content shadow-lg p-4 bg-white rounded">
                  {/* Profile Header */}
                  <div className="profile-header">
                    <img
                      src={
                        userData.student_photo_path
                          ? `${process.env.REACT_APP_BASE_URL || 'https://api-pil.site/'}${userData.student_photo_path}`
                          : 'https://via.placeholder.com/150'
                      }
                      alt="Profile"
                      className="profile-photo"
                    />
                    <div className="profile-info">
                      <h2>{userData.student_name}</h2>
                      {/* Email with Icon */}
                      <p>
                        <FaUser />
                        {userData.email}
                      </p>
                    </div>
                  </div>

                  {/* Details Section */}
                  <div className="details-section">
                    <h3>Personal Information</h3>
                    <ListGroup variant="flush">
                      <ListGroup.Item className="detail-item">
                        <FaCalendarAlt className="icon" />
                        <span className="label">Date of Birth:</span>
                        <span className="value">
                          {userData.dob.day}-{userData.dob.month}-{userData.dob.year} (
                          {userData.dob.in_words})
                        </span>
                      </ListGroup.Item>
                      <ListGroup.Item className="detail-item">
                        <FaHome className="icon" />
                        <span className="label">Address:</span>
                        <span className="value">
                          {userData.residence}, {userData.village}, {userData.tehsil},{' '}
                          {userData.district}
                        </span>
                      </ListGroup.Item>
                      <ListGroup.Item className="detail-item">
                        <FaPhone className="icon" />
                        <span className="label">Father's Contact:</span>
                        <span className="value">
                          {userData.father_contact || 'N/A'}
                        </span>
                      </ListGroup.Item>
                      <ListGroup.Item className="detail-item">
                        <FaUser className="icon" />
                        <span className="label">Blood Group:</span>
                        <span className="value">
                          {userData.blood_group || 'N/A'}
                        </span>
                      </ListGroup.Item>
                    </ListGroup>
                  </div>

                  <div className="details-section">
                    <h3>Academic Information</h3>
                    <ListGroup variant="flush">
                      <ListGroup.Item className="detail-item">
                        <FaUser className="icon" />
                        <span className="label">Class:</span>
                        <span className="value">{userData.class}</span>
                      </ListGroup.Item>
                      <ListGroup.Item className="detail-item">
                        <FaHome className="icon" />
                        <span className="label">School Last Attended:</span>
                        <span className="value">
                          {userData.school_last_attended || 'N/A'}
                        </span>
                      </ListGroup.Item>
                      <ListGroup.Item className="detail-item">
                        <FaUser className="icon" />
                        <span className="label">PEN No:</span>
                        <span className="value">
                          {userData.pen_no || 'N/A'}
                        </span>
                      </ListGroup.Item>
                    </ListGroup>
                  </div>

                  <div className="details-section">
                    <h3>Parental Information</h3>
                    <ListGroup variant="flush">
                      <ListGroup.Item className="detail-item">
                        <FaUser className="icon" />
                        <span className="label">Father's Name:</span>
                        <span className="value">
                          {userData.father_name || 'N/A'}
                        </span>
                      </ListGroup.Item>
                      <ListGroup.Item className="detail-item">
                        <FaUser className="icon" />
                        <span className="label">Mother's Name:</span>
                        <span className="value">
                          {userData.mother_name || 'N/A'}
                        </span>
                      </ListGroup.Item>
                      <ListGroup.Item className="detail-item">
                        <FaPhone className="icon" />
                        <span className="label">Father's Contact:</span>
                        <span className="value">
                          {userData.father_contact || 'N/A'}
                        </span>
                      </ListGroup.Item>
                      <ListGroup.Item className="detail-item">
                        <FaHome className="icon" />
                        <span className="label">Mother's Contact:</span>
                        <span className="value">
                          {userData.mother_contact || 'N/A'}
                        </span>
                      </ListGroup.Item>
                    </ListGroup>
                  </div>

                  <div className="details-section">
                    <h3>Additional Information</h3>
                    <ListGroup variant="flush">
                      <ListGroup.Item className="detail-item">
                        <FaUser className="icon" />
                        <span className="label">Sibling Studying:</span>
                        <span className="value">
                          {userData.sibling_studying ? 'Yes' : 'No'}
                        </span>
                      </ListGroup.Item>
                      {userData.sibling_studying && (
                        <>
                          <ListGroup.Item className="detail-item">
                            <FaUser className="icon" />
                            <span className="label">Sibling Name:</span>
                            <span className="value">
                              {userData.sibling_details?.name || 'N/A'}
                            </span>
                          </ListGroup.Item>
                          <ListGroup.Item className="detail-item">
                            <FaUser className="icon" />
                            <span className="label">Sibling Class:</span>
                            <span className="value">
                              {userData.sibling_details?.class || 'N/A'}
                            </span>
                          </ListGroup.Item>
                        </>
                      )}
                      <ListGroup.Item className="detail-item">
                        <FaPhone className="icon" />
                        <span className="label">Monthly Income:</span>
                        <span className="value">
                          {userData.monthly_income || 'N/A'}
                        </span>
                      </ListGroup.Item>
                    </ListGroup>
                  </div>

                  {/* Actions Section */}
                  <div className="actions-section">
                    <Button
                      variant="primary"
                      className="edit-button"
                      onClick={handleEditProfile}
                    >
                      <FaEdit />
                      Edit Profile
                    </Button>
                    <Button
                      variant="success"
                      className="download-button"
                      onClick={handleDownloadProfile}
                    >
                      <FaDownload />
                      Download Profile
                    </Button>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Styles>
    </>
  );
}

export default AdmissionProfile;
