import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table, Button, Card, Collapse } from 'react-bootstrap';
import styled from 'styled-components';
import HeaderTwo from '../../components/HeaderTwo.js';
import { BreadcrumbBox } from '../../components/common/Breadcrumb.js';
import FooterTwo from '../../components/FooterTwo.js';

// Styles
const Styles = styled.div`
    .main-wrapper {
        font-family: 'Arial', sans-serif;
        background: #f8f9fa;
    }

    .breadcrumb-area {
        background: linear-gradient(135deg, #10b477, #087f5b);
        padding: 40px 0;
        text-align: center;
        color: white;
    }

    .breadcrumb-area h2 {
        font-size: 2.5rem;
        font-weight: bold;
    }

    .datesheet-section {
        padding: 50px 0;
    }

    .section-title {
        font-size: 2.5rem;
        font-weight: bold;
        color: #343a40;
        margin-bottom: 20px;
    }

    .lead-text {
        color: #6c757d;
    }

    .card-header {
        background: linear-gradient(135deg, #10b477, #087f5b);
        color: white;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .card-header:hover {
        background: #087f5b;
    }

    .toggle-icon {
        transition: transform 0.3s ease;
    }

    .toggle-icon.open {
        transform: rotate(180deg);
    }

    .table-container {
        background: #ffffff;
        border-radius: 12px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        padding: 20px;
        margin-top: 20px;
    }

    .table thead {
        background: linear-gradient(135deg, #10b477, #087f5b);
        color: white;
    }

    .table th, .table td {
        text-align: center;
        vertical-align: middle;
        font-size: 1rem;
    }

    .table th {
        font-size: 1.2rem;
        font-weight: bold;
    }

    .download-btn {
        background-color: #10b477;
        color: white;
        border: none;
        padding: 8px 16px;
        border-radius: 8px;
        font-size: 1rem;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }

    .download-btn:hover {
        background-color: #087f5b;
        text-decoration: none;
        color: white;
    }

    .no-data-message {
        font-size: 1.5rem;
        color: #6c757d;
        text-align: center;
        margin-top: 20px;
    }

    @media (max-width: 768px) {
        .table th, .table td {
            font-size: 0.9rem;
        }

        .download-btn {
            padding: 6px 12px;
            font-size: 0.9rem;
        }

        .section-title {
            font-size: 2rem;
        }
    }

    @media (max-width: 576px) {
        .table th, .table td {
            font-size: 0.8rem;
        }

        .download-btn {
            padding: 4px 8px;
            font-size: 0.8rem;
        }

        .section-title {
            font-size: 1.8rem;
        }
    }
`;

// Component
const Datesheet = () => {
    const [datesheetData, setDatesheetData] = useState([]);
    const [openClassIndex, setOpenClassIndex] = useState(null); // To manage which class is open

    useEffect(() => {
        // Fetch datesheet data from the API endpoint
        const fetchDatesheetData = async () => {
            try {
                const response = await fetch('https://api-pil.site/api/auth/datesheet');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                if (data.classes) {
                    // Optionally sort classes alphabetically
                    const sortedClasses = data.classes.sort((a, b) => a.class.localeCompare(b.class));
                    // Optionally sort datesheets within each class by exam_date
                    sortedClasses.forEach(cls => {
                        cls.datesheets.sort((a, b) => new Date(a.exam_date) - new Date(b.exam_date));
                    });
                    setDatesheetData(sortedClasses);
                }
            } catch (error) {
                console.error('Error fetching datesheet data:', error);
            }
        };

        fetchDatesheetData();
    }, []);

    const toggleClass = (index) => {
        if (openClassIndex === index) {
            setOpenClassIndex(null); // Close if already open
        } else {
            setOpenClassIndex(index);
        }
    };

    return (
        <Styles>
            <div className="main-wrapper datesheet-page">
                {/* Header */}
                <HeaderTwo />

                {/* Breadcrumb */}
                <BreadcrumbBox title="Datesheet" />

                {/* Datesheet Section */}
                <section className="datesheet-section">
                    <Container>
                        <Row className="mb-5">
                            <Col lg="12" className="text-center">
                                <h2 className="section-title">Datesheet for All Classes</h2>
                                <p className="lead-text">Browse and download the datesheet for your class below.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                {datesheetData.length > 0 ? (
                                    datesheetData.map((cls, index) => (
                                        <Card key={index} className="mb-3">
                                            <Card.Header onClick={() => toggleClass(index)} className="card-header">
                                                <span>{cls.class}</span>
                                                <i className={`fa fa-chevron-down toggle-icon ${openClassIndex === index ? 'open' : ''}`} aria-hidden="true"></i>
                                            </Card.Header>
                                            <Collapse in={openClassIndex === index}>
                                                <div>
                                                    <Card.Body>
                                                        {cls.datesheets.length > 0 ? (
                                                            <div className="table-container">
                                                                <Table striped bordered hover responsive>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>#</th>
                                                                            <th>Exam Name</th>
                                                                            <th>Exam Date</th>
                                                                            <th>Uploaded Date</th>
                                                                            <th>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {cls.datesheets.map((item, dsIndex) => (
                                                                            <tr key={item._id}>
                                                                                <td>{dsIndex + 1}</td>
                                                                                <td>{item.name}</td>
                                                                                <td>{new Date(item.exam_date).toLocaleDateString()}</td>
                                                                                <td>{new Date(item.uploaded_date).toLocaleDateString()}</td>
                                                                                <td>
                                                                                    <a
                                                                                        className="download-btn"
                                                                                        href={item.pdf_url}
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                    >
                                                                                        <i className="fa fa-download mr-2"></i> Download
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        ) : (
                                                            <p className="no-data-message">No datesheet available for this class.</p>
                                                        )}
                                                    </Card.Body>
                                                </div>
                                            </Collapse>
                                        </Card>
                                    ))
                                ) : (
                                    <p className="no-data-message">No datesheet data available at the moment.</p>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Footer */}
                <FooterTwo />
            </div>
        </Styles>
    );
};

export default Datesheet;
