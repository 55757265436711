// src/pages/account/Register.js

import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Alert,
  Spinner,
  Form,
  Card
} from 'react-bootstrap';
import { FaUpload } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';

import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/account.js';
import registerSchema from '../../validations/registerFormValidation';
import { useAuth } from '../../store/auth.js';

/* ------------------ Styled Components ------------------ */
const FormContainer = styled.div`
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.2);
  max-width: 1200px;
  margin: 40px auto;
  border: 2px solid #000;

  @media (max-width: 768px) {
    padding: 20px;
    max-width: 95%;
  }
`;

const PageTitle = styled.h3`
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: uppercase;
  color: #c00;
`;

const SectionHeading = styled.h4`
  font-size: 18px;
  color: #000;
  font-weight: 700;
  margin-bottom: 20px;
  border-bottom: 2px solid #000;
  padding-bottom: 5px;
  text-transform: uppercase;
  margin-top: 40px;
`;

const StyledLabel = styled.label`
  display: block;
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
  font-weight: 600;
`;

const StyledInput = styled(Form.Control)`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin-bottom: 15px;

  &:focus {
    border-color: #80bdff;
    outline: none;
    box-shadow: 0 0 5px rgba(128, 189, 255, 0.5);
  }
`;

const StyledRadioGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  label {
    margin-right: 15px;
    font-size: 14px;
    font-weight: 600;
  }

  input[type='radio'] {
    margin-right: 5px;
  }
`;

const StyledButton = styled.button`
  padding: 12px 30px;
  font-size: 16px;
  color: #fff;
  background-color: #c00;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: #900;
    transform: scale(1.05);
  }

  &:disabled {
    background-color: #999;
    cursor: not-allowed;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
`;

const NoteText = styled.p`
  font-size: 14px;
  color: #000;
  font-weight: 600;
  margin-top: 20px;
  border-top: 2px solid #000;
  padding-top: 10px;
`;

const FilePreview = styled.div`
  margin-top: 10px;
  img {
    max-width: 100px;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: block;
  }
`;

/* ------------------ Enhanced Alert Styles ------------------ */
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledAlert = styled(Alert)`
  animation: ${fadeIn} 0.5s ease-in-out;
  border-radius: 8px;
  padding: 15px 20px;
  margin-bottom: 20px;

  &.alert-success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
  }

  &.alert-danger {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
  }

  &.alert-info {
    background-color: #d1ecf1;
    border-color: #bee5eb;
    color: #0c5460;
  }

  &.alert-warning {
    background-color: #fff3cd;
    border-color: #ffeeba;
    color: #856404;
  }
`;

/* --------------- ProfilePictureUploadSection --------------- */
function ProfilePictureUploadSection({
  register,
  fileError,
  imagePreview,
  clearFileError
}) {
  return (
    <>
      <SectionHeading>Upload Profile Picture</SectionHeading>
      <p>Please upload your recent photograph (Max size: 3MB):</p>

      {fileError && (
        <StyledAlert variant="danger" dismissible onClose={clearFileError}>
          {fileError}
        </StyledAlert>
      )}

      <Card style={{ border: '1px solid #ccc', borderRadius: '6px' }}>
        <Card.Body>
          <Form.Group controlId="studentPhoto">
            <StyledLabel>
              <FaUpload style={{ marginRight: '5px' }} />
              Student's Recent Photograph <span style={{ color: 'red' }}>*</span>
            </StyledLabel>
            <Form.Control
              type="file"
              name="studentPhoto"
              accept=".jpg,.jpeg,.png"
              isInvalid={!!fileError}
              {...register('studentPhoto')}
              aria-invalid={!!fileError}
              aria-describedby="studentPhotoError"
              required
            />
            {fileError && (
              <Form.Control.Feedback type="invalid" id="studentPhotoError">
                {fileError}
              </Form.Control.Feedback>
            )}
            {imagePreview && (
              <FilePreview>
                <p>Preview:</p>
                <img src={imagePreview} alt="Student Preview" />
              </FilePreview>
            )}
          </Form.Group>
        </Card.Body>
      </Card>
    </>
  );
}

/* ------------------ Main Register Component ------------------ */
function Register() {
  const navigate = useNavigate();
  const { storeTokenInLS } = useAuth();

  // State for previews, errors, success, etc.
  const [imagePreview, setImagePreview] = useState(null);
  const [globalError, setGlobalError] = useState('');
  const [globalSuccess, setGlobalSuccess] = useState('');
  const [fileError, setFileError] = useState('');

  // Allowed file types and size
  const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const maxFileSize = 3 * 1024 * 1024; // 3MB

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    setValue, // To programmatically set field values
  } = useForm({
    resolver: zodResolver(registerSchema),
    mode: 'onBlur', // Validate on blur for better UX
  });

  const sibling = watch('sibling');
  const studentPhoto = watch('studentPhoto');

  // Handle file preview
  useEffect(() => {
    if (studentPhoto && studentPhoto.length > 0) {
      const file = studentPhoto[0];
      if (file) {
        // Validate file size and type
        if (!allowedImageTypes.includes(file.type)) {
          setFileError(`Invalid file type. Allowed types: ${allowedImageTypes.join(', ')}`);
          setImagePreview(null);
          return;
        }
        if (file.size > maxFileSize) {
          setFileError('File size should not exceed 3MB.');
          setImagePreview(null);
          return;
        }

        // Create preview
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
        setFileError('');
      }
    } else {
      setImagePreview(null);
      setFileError('');
    }
  }, [studentPhoto]);

  // Handler to clear file error
  const clearFileError = () => {
    setFileError('');
  };

  // Enhanced handlePadding function
  const handlePadding = (fieldName) => (e) => {
    let value = e.target.value;

    // Remove any non-digit characters
    value = value.replace(/\D/g, '');

    if (value.length === 1) {
      value = `0${value}`;
      setValue(fieldName, value, { shouldValidate: true });
    } else if (value.length > 2) {
      value = value.slice(0, 2);
      setValue(fieldName, value, { shouldValidate: true });
    } else {
      setValue(fieldName, value, { shouldValidate: true });
    }
  };

  // onSubmit handler
  const onSubmit = async (data) => {
    setGlobalError('');
    setGlobalSuccess('');

    // 1) Check if user has uploaded a file
    if (!data.studentPhoto || data.studentPhoto.length === 0) {
      setFileError('Profile image is required.');
      return; // Stop form submission
    }

    // 2) Clear any existing error alert
    setGlobalError('');
    setGlobalSuccess('');

    // 3) Build the form data
    const formData = new FormData();
    formData.append('email', data.email);
    formData.append('password', data.password);
    formData.append('class', data.class);
    formData.append('dated', data.dated);
    formData.append('student_name', data.studentName);
    formData.append('dob_day', data.dob_dd); // Already transformed to two digits
    formData.append('dob_month', data.dob_mm); // Already transformed to two digits
    formData.append('dob_year', data.dob_yyyy);
    formData.append('dob_in_words', data.dob_words);
    formData.append('school_last_attended', data.lastSchoolAttended);
    formData.append('father_name', data.fatherName);
    formData.append('father_profession', data.fatherProfession);
    formData.append('mother_name', data.motherName);
    formData.append('mother_profession', data.motherProfession);
    formData.append('guardian_name', data.guardianName || 'Not Provided');
    formData.append('guardian_profession', data.guardianProfession || 'Not Provided');
    formData.append('father_contact', data.fatherContact);
    // Only append mother_contact if provided
    if (data.motherContact) {
      formData.append('mother_contact', data.motherContact);
    }
    formData.append('residence', data.residence);
    formData.append('village', data.village);
    formData.append('tehsil', data.tehsil);
    formData.append('district', data.district);
    // Only append pen_no if provided
    if (data.penNo) {
      formData.append('pen_no', data.penNo);
    }
    // Only append blood_group if provided
    if (data.bloodGroup) {
      formData.append('blood_group', data.bloodGroup);
    }
    // Append sibling_studying as boolean
    const siblingStudying = data.sibling === 'yes' ? 'true' : 'false';
    formData.append('sibling_studying', siblingStudying);

    if (data.sibling === 'yes') {
      // Append sibling_details as separate fields
      formData.append('sibling_details[name]', data.siblingName);
      formData.append('sibling_details[class]', data.siblingClass);
    }

    // Append the student's photo
    formData.append('studentPhoto', data.studentPhoto[0]);

    // 4) Submit to API
    try {
      const response = await fetch('https://api-pil.site/api/auth/register', {
        method: 'POST',
        body: formData
      });
      const result = await response.json();

      if (response.ok) {
        setGlobalSuccess(result.msg || 'Registration successful!');
        storeTokenInLS(result.token);
        // Navigate to success route after a short delay to show the success message
        setTimeout(() => {
          navigate('/home-adm-process?register=success');
        }, 1500);
      } else {
        setGlobalError(result.msg || 'Registration failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setGlobalError('An error occurred. Please try again.');
    }
  };

  return (
    <Styles>
      <div className="main-wrapper registration-page">
        <HeaderTwo />
        <BreadcrumbBox title="Registration" />

        <section
          className="registration-area"
          style={{ background: '#f5f7fa', paddingBottom: '50px' }}
        >
          <Container>
            <FormContainer>
              <PageTitle>Registration Form Session: 2025</PageTitle>
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                {/* Enhanced Alert for global errors */}
                {globalError && (
                  <StyledAlert
                    variant="danger"
                    dismissible
                    onClose={() => setGlobalError('')}
                  >
                    <strong>Error:</strong> {globalError}
                  </StyledAlert>
                )}

                {/* Enhanced Alert for global success */}
                {globalSuccess && (
                  <StyledAlert
                    variant="success"
                    dismissible
                    onClose={() => setGlobalSuccess('')}
                  >
                    <strong>Success:</strong> {globalSuccess}
                  </StyledAlert>
                )}

                <Row>
                  <Col md={6}>
                    <StyledLabel htmlFor="class">
                      Class in which Admission is sought <span style={{ color: 'red' }}>*</span>
                    </StyledLabel>
                    <StyledInput
                      type="text"
                      id="class"
                      placeholder="e.g. Class 5"
                      list="classes"
                      isInvalid={!!errors.class}
                      {...register('class')}
                      aria-invalid={!!errors.class}
                      aria-describedby="classError"
                      required
                    />
                    <datalist id="classes">
                      <option value="Pre Nursery" />
                      <option value="Nursery" />
                      <option value="LKG" />
                      <option value="UKG" />
                      <option value="Class 1" />
                      <option value="Class 2" />
                      <option value="Class 3" />
                      <option value="Class 4" />
                      <option value="Class 5" />
                      <option value="Class 6" />
                      <option value="Class 7" />
                      <option value="Class 8" />
                      <option value="Class 9" />
                      <option value="Class 10" />
                    </datalist>
                    {errors.class && (
                      <StyledAlert variant="danger" id="classError">
                        {errors.class.message}
                      </StyledAlert>
                    )}
                  </Col>

                  <Col md={6}>
                    <StyledLabel htmlFor="dated">Dated <span style={{ color: 'red' }}>*</span></StyledLabel>
                    <StyledInput
                      type="date"
                      id="dated"
                      isInvalid={!!errors.dated}
                      {...register('dated')}
                      aria-invalid={!!errors.dated}
                      aria-describedby="datedError"
                      required
                    />
                    {errors.dated && (
                      <StyledAlert variant="danger" id="datedError">
                        {errors.dated.message}
                      </StyledAlert>
                    )}
                  </Col>
                </Row>

                <StyledLabel htmlFor="studentName">
                  Name of the Student <span style={{ color: 'red' }}>*</span>
                </StyledLabel>
                <StyledInput
                  type="text"
                  id="studentName"
                  placeholder="Enter student name"
                  isInvalid={!!errors.studentName}
                  {...register('studentName')}
                  aria-invalid={!!errors.studentName}
                  aria-describedby="studentNameError"
                  required
                />
                {errors.studentName && (
                  <StyledAlert variant="danger" id="studentNameError">
                    {errors.studentName.message}
                  </StyledAlert>
                )}

                <Row>
                  <Col xs={3}>
                    <StyledLabel htmlFor="dob_dd">D.O.B (DD) <span style={{ color: 'red' }}>*</span></StyledLabel>
                    <StyledInput
                      type="text"
                      placeholder="DD"
                      id="dob_dd"
                      maxLength="2"
                      isInvalid={!!errors.dob_dd}
                      {...register('dob_dd')}
                      onBlur={handlePadding('dob_dd')}
                      pattern="\d{2}"
                      title="Enter day as two digits (e.g., 06)"
                      aria-invalid={!!errors.dob_dd}
                      aria-describedby="dob_ddError"
                      required
                      inputMode="numeric"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      }}
                    />
                    {errors.dob_dd && (
                      <StyledAlert variant="danger" id="dob_ddError">
                        {errors.dob_dd.message}
                      </StyledAlert>
                    )}
                  </Col>
                  <Col xs={3}>
                    <StyledLabel htmlFor="dob_mm">D.O.B (MM) <span style={{ color: 'red' }}>*</span></StyledLabel>
                    <StyledInput
                      type="text"
                      placeholder="MM"
                      id="dob_mm"
                      maxLength="2"
                      isInvalid={!!errors.dob_mm}
                      {...register('dob_mm')}
                      onBlur={handlePadding('dob_mm')}
                      pattern="\d{2}"
                      title="Enter month as two digits (e.g., 03)"
                      aria-invalid={!!errors.dob_mm}
                      aria-describedby="dob_mmError"
                      required
                      inputMode="numeric"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      }}
                    />
                    {errors.dob_mm && (
                      <StyledAlert variant="danger" id="dob_mmError">
                        {errors.dob_mm.message}
                      </StyledAlert>
                    )}
                  </Col>
                  <Col xs={3}>
                    <StyledLabel htmlFor="dob_yyyy">D.O.B (YYYY) <span style={{ color: 'red' }}>*</span></StyledLabel>
                    <StyledInput
                      type="text"
                      placeholder="YYYY"
                      id="dob_yyyy"
                      maxLength="4"
                      isInvalid={!!errors.dob_yyyy}
                      {...register('dob_yyyy')}
                      pattern="\d{4}"
                      title="Enter year as four digits (e.g., 2025)"
                      aria-invalid={!!errors.dob_yyyy}
                      aria-describedby="dob_yyyyError"
                      required
                      inputMode="numeric"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      }}
                    />
                    {errors.dob_yyyy && (
                      <StyledAlert variant="danger" id="dob_yyyyError">
                        {errors.dob_yyyy.message}
                      </StyledAlert>
                    )}
                  </Col>
                  <Col xs={3}>
                    <StyledLabel htmlFor="dob_words">In Words <span style={{ color: 'red' }}>*</span></StyledLabel>
                    <StyledInput
                      type="text"
                      placeholder="e.g. Thirteenth December Two Thousand Nine"
                      id="dob_words"
                      isInvalid={!!errors.dob_words}
                      {...register('dob_words')}
                      aria-invalid={!!errors.dob_words}
                      aria-describedby="dob_wordsError"
                      required
                    />
                    {errors.dob_words && (
                      <StyledAlert variant="danger" id="dob_wordsError">
                        {errors.dob_words.message}
                      </StyledAlert>
                    )}
                  </Col>
                </Row>

                <StyledLabel htmlFor="lastSchoolAttended">
                  Name of School Last Attended <span style={{ color: 'red' }}>*</span>
                </StyledLabel>
                <StyledInput
                  type="text"
                  id="lastSchoolAttended"
                  placeholder="Enter previous school name"
                  isInvalid={!!errors.lastSchoolAttended}
                  {...register('lastSchoolAttended')}
                  aria-invalid={!!errors.lastSchoolAttended}
                  aria-describedby="lastSchoolAttendedError"
                  required
                />
                {errors.lastSchoolAttended && (
                  <StyledAlert variant="danger" id="lastSchoolAttendedError">
                    {errors.lastSchoolAttended.message}
                  </StyledAlert>
                )}

                <SectionHeading>Parent/Guardian Details</SectionHeading>
                <Row>
                  <Col md={6}>
                    <StyledLabel htmlFor="fatherName">Father's Name <span style={{ color: 'red' }}>*</span></StyledLabel>
                    <StyledInput
                      type="text"
                      id="fatherName"
                      isInvalid={!!errors.fatherName}
                      {...register('fatherName')}
                      aria-invalid={!!errors.fatherName}
                      aria-describedby="fatherNameError"
                      required
                    />
                    {errors.fatherName && (
                      <StyledAlert variant="danger" id="fatherNameError">
                        {errors.fatherName.message}
                      </StyledAlert>
                    )}
                  </Col>
                  <Col md={6}>
                    <StyledLabel htmlFor="fatherProfession">Father's Profession <span style={{ color: 'red' }}>*</span></StyledLabel>
                    <StyledInput
                      type="text"
                      id="fatherProfession"
                      isInvalid={!!errors.fatherProfession}
                      {...register('fatherProfession')}
                      aria-invalid={!!errors.fatherProfession}
                      aria-describedby="fatherProfessionError"
                      required
                    />
                    {errors.fatherProfession && (
                      <StyledAlert variant="danger" id="fatherProfessionError">
                        {errors.fatherProfession.message}
                      </StyledAlert>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <StyledLabel htmlFor="motherName">Mother's Name <span style={{ color: 'red' }}>*</span></StyledLabel>
                    <StyledInput
                      type="text"
                      id="motherName"
                      isInvalid={!!errors.motherName}
                      {...register('motherName')}
                      aria-invalid={!!errors.motherName}
                      aria-describedby="motherNameError"
                      required
                    />
                    {errors.motherName && (
                      <StyledAlert variant="danger" id="motherNameError">
                        {errors.motherName.message}
                      </StyledAlert>
                    )}
                  </Col>
                  <Col md={6}>
                    <StyledLabel htmlFor="motherProfession">Mother's Profession <span style={{ color: 'red' }}>*</span></StyledLabel>
                    <StyledInput
                      type="text"
                      id="motherProfession"
                      isInvalid={!!errors.motherProfession}
                      {...register('motherProfession')}
                      aria-invalid={!!errors.motherProfession}
                      aria-describedby="motherProfessionError"
                      required
                    />
                    {errors.motherProfession && (
                      <StyledAlert variant="danger" id="motherProfessionError">
                        {errors.motherProfession.message}
                      </StyledAlert>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <StyledLabel htmlFor="guardianName">Guardian's Name</StyledLabel>
                    <StyledInput
                      type="text"
                      id="guardianName"
                      isInvalid={!!errors.guardianName}
                      {...register('guardianName')}
                      aria-invalid={!!errors.guardianName}
                      aria-describedby="guardianNameError"
                      /* Removed 'required' to make it optional */
                    />
                    {errors.guardianName && (
                      <StyledAlert variant="danger" id="guardianNameError">
                        {errors.guardianName.message}
                      </StyledAlert>
                    )}
                  </Col>
                  <Col md={6}>
                    <StyledLabel htmlFor="guardianProfession">Guardian's Profession</StyledLabel>
                    <StyledInput
                      type="text"
                      id="guardianProfession"
                      isInvalid={!!errors.guardianProfession}
                      {...register('guardianProfession')}
                      aria-invalid={!!errors.guardianProfession}
                      aria-describedby="guardianProfessionError"
                      /* Removed 'required' to make it optional */
                    />
                    {errors.guardianProfession && (
                      <StyledAlert variant="danger" id="guardianProfessionError">
                        {errors.guardianProfession.message}
                      </StyledAlert>
                    )}
                  </Col>
                </Row>

                <SectionHeading>Emergency Contact</SectionHeading>
                <Row>
                  <Col md={6}>
                    <StyledLabel htmlFor="fatherContact">Father's Contact No. <span style={{ color: 'red' }}>*</span></StyledLabel>
                    <StyledInput
                      type="text"
                      id="fatherContact"
                      placeholder="Enter father's contact no."
                      isInvalid={!!errors.fatherContact}
                      {...register('fatherContact')}
                      pattern="\d{10,15}"
                      title="Enter a 10 to 15-digit contact number"
                      aria-invalid={!!errors.fatherContact}
                      aria-describedby="fatherContactError"
                      required
                      inputMode="numeric"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      }}
                    />
                    {errors.fatherContact && (
                      <StyledAlert variant="danger" id="fatherContactError">
                        {errors.fatherContact.message}
                      </StyledAlert>
                    )}
                  </Col>
                  <Col md={6}>
                    <StyledLabel htmlFor="motherContact">Mother's Contact No.</StyledLabel>
                    <StyledInput
                      type="text"
                      id="motherContact"
                      placeholder="Enter mother's contact no."
                      isInvalid={!!errors.motherContact}
                      {...register('motherContact')}
                      pattern="\d{10,15}"
                      title="Enter a 10 to 15-digit contact number"
                      aria-invalid={!!errors.motherContact}
                      aria-describedby="motherContactError"
                      inputMode="numeric"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      }}
                    />
                    {errors.motherContact && (
                      <StyledAlert variant="danger" id="motherContactError">
                        {errors.motherContact.message}
                      </StyledAlert>
                    )}
                  </Col>
                </Row>

                <SectionHeading>Address</SectionHeading>
                <Row>
                  <Col md={3}>
                    <StyledLabel htmlFor="residence">Residence <span style={{ color: 'red' }}>*</span></StyledLabel>
                    <StyledInput
                      type="text"
                      id="residence"
                      isInvalid={!!errors.residence}
                      {...register('residence')}
                      aria-invalid={!!errors.residence}
                      aria-describedby="residenceError"
                      required
                    />
                    {errors.residence && (
                      <StyledAlert variant="danger" id="residenceError">
                        {errors.residence.message}
                      </StyledAlert>
                    )}
                  </Col>
                  <Col md={3}>
                    <StyledLabel htmlFor="village">Village/Town <span style={{ color: 'red' }}>*</span></StyledLabel>
                    <StyledInput
                      type="text"
                      id="village"
                      isInvalid={!!errors.village}
                      {...register('village')}
                      aria-invalid={!!errors.village}
                      aria-describedby="villageError"
                      required
                    />
                    {errors.village && (
                      <StyledAlert variant="danger" id="villageError">
                        {errors.village.message}
                      </StyledAlert>
                    )}
                  </Col>
                  <Col md={3}>
                    <StyledLabel htmlFor="tehsil">Tehsil <span style={{ color: 'red' }}>*</span></StyledLabel>
                    <StyledInput
                      type="text"
                      id="tehsil"
                      isInvalid={!!errors.tehsil}
                      {...register('tehsil')}
                      aria-invalid={!!errors.tehsil}
                      aria-describedby="tehsilError"
                      required
                    />
                    {errors.tehsil && (
                      <StyledAlert variant="danger" id="tehsilError">
                        {errors.tehsil.message}
                      </StyledAlert>
                    )}
                  </Col>
                  <Col md={3}>
                    <StyledLabel htmlFor="district">District <span style={{ color: 'red' }}>*</span></StyledLabel>
                    <StyledInput
                      type="text"
                      id="district"
                      isInvalid={!!errors.district}
                      {...register('district')}
                      aria-invalid={!!errors.district}
                      aria-describedby="districtError"
                      required
                    />
                    {errors.district && (
                      <StyledAlert variant="danger" id="districtError">
                        {errors.district.message}
                      </StyledAlert>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <StyledLabel htmlFor="bloodGroup">Student's Blood Group</StyledLabel>
                    <StyledInput
                      type="text"
                      id="bloodGroup"
                      placeholder="e.g. A+, B-, O+"
                      isInvalid={!!errors.bloodGroup}
                      {...register('bloodGroup')}
                      aria-invalid={!!errors.bloodGroup}
                      aria-describedby="bloodGroupError"
                      /* Removed 'required' to make it optional */
                    />
                    {errors.bloodGroup && (
                      <StyledAlert variant="danger" id="bloodGroupError">
                        {errors.bloodGroup.message}
                      </StyledAlert>
                    )}
                  </Col>
                  <Col md={6}>
                    <StyledLabel htmlFor="penNo">PEN No</StyledLabel>
                    <StyledInput
                      type="text"
                      id="penNo"
                      isInvalid={!!errors.penNo}
                      {...register('penNo')}
                      aria-invalid={!!errors.penNo}
                      aria-describedby="penNoError"
                      /* Removed 'required' to make it optional */
                    />
                    {errors.penNo && (
                      <StyledAlert variant="danger" id="penNoError">
                        {errors.penNo.message}
                      </StyledAlert>
                    )}
                  </Col>
                </Row>

                <SectionHeading>Sibling Details</SectionHeading>
                <StyledLabel>
                  If any sibling is studying in Pioneer Institute of Learning <span style={{ color: 'red' }}>*</span>
                </StyledLabel>
                <StyledRadioGroup>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    value="yes"
                    {...register('sibling')}
                    id="siblingYes"
                    name="sibling"
                    inline
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    value="no"
                    {...register('sibling')}
                    id="siblingNo"
                    name="sibling"
                    inline
                  />
                </StyledRadioGroup>
                {errors.sibling && (
                  <StyledAlert variant="danger">
                    {errors.sibling.message}
                  </StyledAlert>
                )}

                {sibling === 'yes' && (
                  <Row>
                    <Col md={6}>
                      <StyledLabel htmlFor="siblingName">Sibling Name <span style={{ color: 'red' }}>*</span></StyledLabel>
                      <StyledInput
                        type="text"
                        id="siblingName"
                        isInvalid={!!errors.siblingName}
                        {...register('siblingName')}
                        aria-invalid={!!errors.siblingName}
                        aria-describedby="siblingNameError"
                        required
                      />
                      {errors.siblingName && (
                        <StyledAlert variant="danger" id="siblingNameError">
                          {errors.siblingName.message}
                        </StyledAlert>
                      )}
                    </Col>
                    <Col md={6}>
                      <StyledLabel htmlFor="siblingClass">Sibling Class <span style={{ color: 'red' }}>*</span></StyledLabel>
                      <StyledInput
                        type="text"
                        id="siblingClass"
                        isInvalid={!!errors.siblingClass}
                        {...register('siblingClass')}
                        aria-invalid={!!errors.siblingClass}
                        aria-describedby="siblingClassError"
                        required
                      />
                      {errors.siblingClass && (
                        <StyledAlert variant="danger" id="siblingClassError">
                          {errors.siblingClass.message}
                        </StyledAlert>
                      )}
                    </Col>
                  </Row>
                )}

                <SectionHeading>Account & Authentication</SectionHeading>
                <Row>
                  <Col md={6}>
                    <StyledLabel htmlFor="email">Email <span style={{ color: 'red' }}>*</span></StyledLabel>
                    <StyledInput
                      type="email"
                      id="email"
                      placeholder="Enter your email"
                      isInvalid={!!errors.email}
                      {...register('email')}
                      aria-invalid={!!errors.email}
                      aria-describedby="emailError"
                      required
                    />
                    {errors.email && (
                      <StyledAlert variant="danger" id="emailError">
                        {errors.email.message}
                      </StyledAlert>
                    )}
                  </Col>
                  <Col md={6}>
                    <StyledLabel htmlFor="password">Password <span style={{ color: 'red' }}>*</span></StyledLabel>
                    <StyledInput
                      type="password"
                      id="password"
                      placeholder="Enter a password"
                      isInvalid={!!errors.password}
                      {...register('password')}
                      aria-invalid={!!errors.password}
                      aria-describedby="passwordError"
                      required
                    />
                    {errors.password && (
                      <StyledAlert variant="danger" id="passwordError">
                        {errors.password.message}
                      </StyledAlert>
                    )}
                  </Col>
                </Row>

                {/* Profile Picture Upload (Required) */}
                <ProfilePictureUploadSection
                  register={register}
                  fileError={fileError}
                  imagePreview={imagePreview}
                  clearFileError={clearFileError}
                />

                <StyledButton type="submit" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{' '}
                      Registering...
                    </>
                  ) : (
                    'Register Now'
                  )}
                </StyledButton>

                <NoteText>
                  <strong>Note:</strong> Students must be accompanied by their
                  parents at the time of Interview.
                </NoteText>
              </form>
            </FormContainer>
          </Container>
        </section>
        <FooterTwo />
      </div>
    </Styles>
  );
}

export default Register;
