import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/stickyMenu.js";

function StickyMenu() {
    useEffect(() => {
        const handleScroll = () => {
            const stickyMenu = document.querySelector(".sticky-menu");

            if (stickyMenu) {
                if (window.scrollY > 160) {
                    stickyMenu.classList.add("sticky");
                } else {
                    stickyMenu.classList.remove("sticky");
                }
            }
        };

        window.addEventListener("scroll", handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <Styles>
            {/* Sticky Menu */}
            <section className="sticky-menu">
                <Container fluid>
                    <Row className="align-items-center">
                        <Col md="1">
                            <div className="logo">
                                <Link to={process.env.PUBLIC_URL + "/"}>
                                    <img src={process.env.PUBLIC_URL + "/assets/images/wt-logo.png"} alt="Logo" />
                                </Link>
                            </div>
                        </Col>
                        <Col md="11" className="d-flex justify-content-start">
                            <div className="menu-box d-flex justify-content-start align-items-center">
                                <ul className="nav menu-nav">
                                    <li className="nav-item">
                                        <Link className="nav-link" to={process.env.PUBLIC_URL + "/"}>Home</Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to="#">Admissions <i className="las la-angle-down"></i></Link>
                                        <ul className="dropdown list-unstyled">
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/admission-requirements"}>Admission Requirements</Link></li>
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/admission-process"}>Admission Process</Link></li>
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/registration"}>Apply Now</Link></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to="#">School <i className="las la-angle-down"></i></Link>
                                        <ul className="dropdown list-unstyled">
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/mission-vision"}>Mission And Vision</Link></li>
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/our-history"}>History</Link></li>
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/message-from/chairman"}>Message from the Chairman</Link></li>
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/message-from/principal"}>Message from the Principal</Link></li>
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/message-from/administrator"}>Message from Administrator</Link></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={process.env.PUBLIC_URL + "/gallery"}>Gallery</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={process.env.PUBLIC_URL + "/about"}>About Us</Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to={`${process.env.PUBLIC_URL}/`} data-toggle="dropdown">
                                            Updates & Resources <i className="las la-angle-down"></i>
                                        </Link>
                                        <ul className="dropdown list-unstyled">

                                            <li className="nav-item">
                                                <Link className="nav-link" to={`${process.env.PUBLIC_URL}/messages`}>Messages</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to={`${process.env.PUBLIC_URL}/datesheet`}>Datesheet</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to={`${process.env.PUBLIC_URL}/syllabus`}>Syllabus</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to={`${process.env.PUBLIC_URL}/assignments`}>Assignments</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to={`${process.env.PUBLIC_URL}/events`}>Events</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to={`${process.env.PUBLIC_URL}/news`}>News</Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to="#">Login <i className="las la-angle-down"></i></Link>
                                        <ul className="dropdown list-unstyled">
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/login?login=student"}>Student Login</Link></li>
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/login?login=teacher"}>Teacher Login</Link></li>
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/login?login=admission"}>Admission Portal</Link></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={process.env.PUBLIC_URL + "/contact"}>Contact</Link>
                                    </li>
                                </ul>
                                <div className="apply-btn">
                                    <Link to={process.env.PUBLIC_URL + "/registration"}>
                                        <i className="las la-clipboard-list"></i> Apply Now
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Styles>
    );
}

export default StickyMenu;
