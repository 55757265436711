import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Tab, Form, Button } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import styled from 'styled-components';
import { Styles } from './styles/account.js';
import { useAuth } from '../../store/auth.js';

const CustomTab = styled.div`
    text-align: center;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    &:hover {
        background: #e2e2e2;
    }
    .active {
        background: #007bff;
        color: #ffffff;
        font-weight: bold;
    }
`;

function Login() {
    const { storeTokenInLS, isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/home-adm-process");
        }
    }, [isLoggedIn, navigate]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [id]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { email, password } = formData;

        if (email.trim() === "" || password.trim() === "") {
            alert("Email and Password cannot be blank.");
            return;
        }

        try {
            const response = await fetch('https://api-pil.site/api/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password })
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Login successful:', data);
                storeTokenInLS(data.data.token);
                navigate("/home-adm-process");
            } else {
                alert("Login Failed");
            }
        } catch (error) {
            console.error('Login error:', error);
        }
    };

    return (
        <Styles>
            <div className="main-wrapper login-page">
                <HeaderTwo />
                <BreadcrumbBox title="Log In" />
                <section className="login-area">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="login-box">
                                    <CustomTab>
                                        <div className="login-title text-center">
                                            <h3>Admission Process Log In</h3>
                                        </div>
                                        <form id="form_login" className="form" onSubmit={handleSubmit}>
                                            <p className="form-control">
                                                <label htmlFor="email">Email</label>
                                                <input
                                                    type="email"
                                                    placeholder="Email"
                                                    id="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                />
                                                <span className="login_input-msg"></span>
                                            </p>
                                            <p className="form-control">
                                                <label htmlFor="password">Password</label>
                                                <input
                                                    type="password"
                                                    placeholder="*******"
                                                    id="password"
                                                    value={formData.password}
                                                    onChange={handleInputChange}
                                                />
                                                <span className="login_input-msg"></span>
                                            </p>
                                            <Button type="submit" className="w-100 mt-3">Log In</Button>
                                        </form>
                                    </CustomTab>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <FooterTwo />
            </div>
        </Styles>
    );
}

export default Login;
