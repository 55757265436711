import React, { useState, useEffect } from 'react';
import HeroImage from './components/HeroImage';
import ServiceBox from './components/ServiceBox';
import AboutUsTwo from './components/AboutUsTwo';
import CourseSlider from './components/CourseSlider';
import NumberCounter from './components/NumberCounter';
import TeamSlider from './components/TeamSlider';
import TestimonialSlider from './components/TestimonialSlider';
import FooterTwo from './components/FooterTwo';
import CourseFilter from './components/CourseFilter';
import CampusTour from './components/CampusTour';
import HelpArea from './components/HelpArea';
import Header from './components/Header';
import AdmissionModal from './components/AdmissionModal';

const HomeTwo = () => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        // Show the modal when the component loads
        setShowModal(true);
    }, []);

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <div className="main-wrapper">
            {/* Header */}
            <Header />
            <HeroImage />

            {/* Admission Modal */}
            <AdmissionModal show={showModal} handleClose={handleClose} />

            {/* Other components */}
            <ServiceBox />
            <AboutUsTwo />
            <CourseSlider />
            <NumberCounter />
            <CourseFilter />
            <TestimonialSlider />
            <TeamSlider />
            <HelpArea />
            <CampusTour />
            <FooterTwo />
        </div>
    );
};

export default HomeTwo;
