// AnnouncementCenter.js

import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table, Badge, Modal, Button, Form } from 'react-bootstrap';
import styled from 'styled-components';
import HeaderTwo from '../../components/HeaderTwo.js';
import { BreadcrumbBox } from '../../components/common/Breadcrumb.js';
import FooterTwo from '../../components/FooterTwo.js';
import { FaEnvelopeOpenText, FaEnvelope, FaEye, FaTimes } from 'react-icons/fa';

// Styles
const Styles = styled.div`
    .main-wrapper {
        font-family: 'Arial', sans-serif;
        background: #f8f9fa;
    }

    .breadcrumb-area {
        background: linear-gradient(135deg, #10b477, #087f5b);
        padding: 40px 0;
        text-align: center;
        color: white;
    }

    .breadcrumb-area h2 {
        font-size: 2rem;
        font-weight: bold;
    }

    .announcement-section {
        padding: 60px 0;
    }

    .section-title {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .lead {
        font-size: 1rem;
    }

    .table-container {
        background: #ffffff;
        padding: 20px;
        border-radius: 15px;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
    }

    .table thead {
        background: #10b477;
        color: white;
    }

    .table tbody tr:hover {
        background: #f1f1f1;
        cursor: pointer;
    }

    .badge-read {
        background-color: #6c757d;
        color: white;
    }

    .badge-unread {
        background-color: #10b477;
        color: white;
    }

    .modal-header {
        border-bottom: none;
    }

    .modal-title {
        font-size: 1.8rem;
        font-weight: bold;
        color: #333333;
    }

    .modal-body {
        padding: 20px;
    }

    .modal-footer {
        border-top: none;
    }

    @media (max-width: 768px) {
        .breadcrumb-area h2 {
            font-size: 1.5rem;
        }

        .section-title {
            font-size: 1.5rem;
        }

        .lead {
            font-size: 0.9rem;
        }

        .modal-title {
            font-size: 1.5rem;
        }
    }

    @media (max-width: 576px) {
        .breadcrumb-area {
            padding: 20px 0;
        }

        .section-title {
            font-size: 1.3rem;
        }

        .lead {
            font-size: 0.8rem;
        }

        .modal-title {
            font-size: 1.3rem;
        }

        .modal-body {
            padding: 15px;
        }
    }
`;

// Component
const AnnouncementCenter = () => {
    const [messages, setMessages] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // Fetch messages data from the API endpoint
        const fetchMessages = async () => {
            try {
                const response = await fetch('https://api-pil.site/api/auth/messages');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                if (data.success) {
                    // Initialize read status from localStorage
                    const storedStatus = JSON.parse(localStorage.getItem('readStatus')) || {};
                    const messagesWithStatus = data.data.map(message => ({
                        ...message,
                        read: storedStatus[message._id] || false,
                    }));
                    setMessages(messagesWithStatus);
                }
            } catch (error) {
                console.error('Error fetching messages data:', error);
            }
        };

        fetchMessages();
    }, []);

    // Save read status to localStorage whenever messages change
    useEffect(() => {
        const status = {};
        messages.forEach(message => {
            status[message._id] = message.read;
        });
        localStorage.setItem('readStatus', JSON.stringify(status));
    }, [messages]);

    // Function to handle row click
    const handleRowClick = (message) => {
        setSelectedMessage(message);
        setShowModal(true);
        if (!message.read) {
            markAsRead(message._id);
        }
    };

    // Function to mark a message as read
    const markAsRead = (id) => {
        setMessages(prevMessages =>
            prevMessages.map(msg =>
                msg._id === id ? { ...msg, read: true } : msg
            )
        );
    };

    // Function to mark a message as unread
    const markAsUnread = (id) => {
        setMessages(prevMessages =>
            prevMessages.map(msg =>
                msg._id === id ? { ...msg, read: false } : msg
            )
        );
    };

    // Function to handle modal close
    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedMessage(null);
    };

    // Function to handle search
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    // Filter messages based on search term
    const filteredMessages = messages.filter(message =>
        message.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        message.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
        message.targetAudience.toLowerCase().includes(searchTerm.toLowerCase()) ||
        message.sentBy.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Styles>
            <div className="main-wrapper announcement-page">
                {/* Header */}
                <HeaderTwo />

                {/* Breadcrumb */}
                <BreadcrumbBox title="Announcement Center" />

                {/* Announcement Section */}
                <section className="announcement-section">
                    <Container>
                        <Row className="mb-4">
                            <Col lg="12" className="text-center">
                                <h2 className="section-title">All Announcements</h2>
                                <p className="lead text-muted">View and manage all official announcements sent to students.</p>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col lg="4" md="6" sm="12" className="mb-2">
                                <Form.Control
                                    type="text"
                                    placeholder="Search Announcements..."
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <div className="table-container">
                                    <Table striped bordered hover responsive>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Title</th>
                                                <th>Audience</th>
                                                <th>Sent By</th>
                                                <th>Sent At</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredMessages.length > 0 ? (
                                                filteredMessages.map((message, index) => (
                                                    <tr key={message._id} onClick={() => handleRowClick(message)}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            {message.read ? (
                                                                <FaEnvelopeOpenText color="#6c757d" title="Read" />
                                                            ) : (
                                                                <FaEnvelope color="#10b477" title="Unread" />
                                                            )} {' '}
                                                            {message.title}
                                                        </td>
                                                        <td>{message.targetAudience}</td>
                                                        <td>{message.sentBy}</td>
                                                        <td>{new Date(message.sentAt).toLocaleString()}</td>
                                                        <td>
                                                            {message.read ? (
                                                                <Badge variant="read">Read</Badge>
                                                            ) : (
                                                                <Badge variant="unread">Unread</Badge>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <Button
                                                                variant="link"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    message.read ? markAsUnread(message._id) : markAsRead(message._id);
                                                                }}
                                                            >
                                                                {message.read ? <FaEnvelope /> : <FaEnvelopeOpenText />}
                                                            </Button>
                                                            <Button
                                                                variant="link"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleRowClick(message);
                                                                }}
                                                            >
                                                                <FaEye />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="7" className="text-center">No announcements found.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Footer */}
                <FooterTwo />

                {/* Modal for Full Announcement */}
                {selectedMessage && (
                    <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>{selectedMessage.title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{selectedMessage.content}</p>
                            <div className="mb-3">
                                <Badge variant="audiance">Audience: {selectedMessage.targetAudience}</Badge>{' '}
                                <Badge variant="sender">Sent By: {selectedMessage.sentBy}</Badge>
                            </div>
                            <p className="sent-at">Sent At: {new Date(selectedMessage.sentAt).toLocaleString()}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>
        </Styles>
    );
};

export default AnnouncementCenter;
