// ./src/pages/admission/components/LoggedNavbar.js

import React from 'react';
import { Navbar, Nav, Dropdown, Image, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { useAuth } from '../../../store/auth';
import { FaUserCircle, FaCog, FaSignOutAlt } from 'react-icons/fa';

// Keyframes for animations
const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

// Styled Components for Navbar
const StyledNavbar = styled(Navbar)`
  background-color: #ffffff; /* Light background */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px 30px;
  transition: all 0.3s ease;

  .navbar-brand img {
    height: 50px;
    width: auto;
    transition: transform 0.3s ease;
  }

  .navbar-brand img:hover {
    transform: scale(1.05);
  }

  .dropdown-toggle {
    color: #374151; /* Dark text */
    font-weight: 500;
    font-size: 14px;
    padding: 8px 12px;
    border-radius: 8px;
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    align-items: center;
    background-color: #f3f4f6; /* Light gray background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    text-decoration: none;

    &:hover {
      background-color: #e5e7eb; /* Slightly darker on hover */
      color: #111827;
    }
  }

  .dropdown-menu {
    border-radius: 8px;
    background-color: #ffffff; /* White background */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    padding: 10px 0;
    min-width: 200px;
  }

  .dropdown-item {
    padding: 10px 20px;
    color: #374151;
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    align-items: center;
    font-size: 14px;

    &:hover {
      background-color: #f3f4f6;
      color: #111827;
    }

    .dropdown-icon {
      margin-right: 10px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .user-avatar {
    border-radius: 50%;
    border: 2px solid #d1d5db;
    width: 40px;
    height: 40px;
    object-fit: cover;
    transition: transform 0.3s ease;
    animation: ${pulse} 4s infinite;

    &:hover {
      transform: scale(1.05);
    }
  }

  .user-name {
    margin-left: 10px;
    font-weight: 500;
    color: #374151;
    display: none;

    @media (min-width: 768px) {
      display: inline;
    }
  }

  @media (max-width: 768px) {
    .navbar-brand img {
      height: 40px;
    }

    .dropdown-toggle {
      font-size: 12px;
      padding: 6px 10px;
    }

    .dropdown-menu {
      min-width: 150px;
    }

    .user-avatar {
      width: 35px;
      height: 35px;
    }
  }
`;

const LoggedNavbar = () => {
  const { user } = useAuth();

  return (
    <StyledNavbar expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src={process.env.PUBLIC_URL + "/assets/images/nav-logo.png"} alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav" className="justify-content-end">
          <Nav className="align-items-center">
            <Dropdown align="end">
              <Dropdown.Toggle variant="link" id="dropdown-basic" className="d-flex align-items-center">
                <Image
                  src={user.avatar || "https://cdn-icons-png.flaticon.com/512/149/149071.png"}
                  roundedCircle
                  width="40"
                  height="40"
                  className="me-2 user-avatar"
                  alt="User Avatar"
                />
                <span className="user-name">{user.student_name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/profile">
                  <FaUserCircle className="dropdown-icon" />
                  Profile
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/settings">
                  <FaCog className="dropdown-icon" />
                  Settings
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item as={Link} to="/logout">
                  <FaSignOutAlt className="dropdown-icon" />
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </StyledNavbar>
  );
};

export default LoggedNavbar;
