// ./src/pages/admission/AdmissionProcess.js

import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import LoggedNavbar from './components/LoggedNavbar';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import { FaCheckCircle, FaTimesCircle, FaRegClock, FaRegSmile } from 'react-icons/fa';

// Keyframes for animations
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const slideIn = keyframes`
  from { opacity: 0; transform: translateX(-50px); }
  to { opacity: 1; transform: translateX(0); }
`;

// Styled Components
const Styles = styled.div`
  .admission-process-page {
    font-family: 'Roboto', sans-serif;
    background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
    min-height: 100vh;
    padding: 60px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .admission-process-content {
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.15);
    padding: 60px 80px;
    max-width: 1000px; /* Increased width */
    width: 100%;
    animation: ${fadeIn} 1s ease-out;
    position: relative;
    overflow: hidden;

    /* Decorative Elements */
    &:before {
      content: '';
      position: absolute;
      top: -60px;
      right: -60px;
      width: 120px;
      height: 120px;
      background: rgba(59, 130, 246, 0.2);
      border-radius: 50%;
      animation: ${pulse} 5s infinite;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -60px;
      left: -60px;
      width: 120px;
      height: 120px;
      background: rgba(16, 185, 129, 0.2);
      border-radius: 50%;
      animation: ${pulse} 7s infinite;
    }

    /* Success Alert */
    .alert-success {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #d1fae5;
      color: #065f46;
      padding: 25px 30px;
      border-radius: 15px;
      margin-bottom: 40px;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
      animation: ${fadeIn} 1s ease-out;

      svg {
        margin-right: 15px;
        font-size: 32px;
      }

      span {
        font-size: 18px;
        font-weight: 600;
      }
    }

    /* Heading Styles */
    .heading {
      margin-bottom: 60px;

      h2 {
        font-size: 40px; /* Increased font size */
        color: #1e293b;
        font-weight: 800;
        margin-bottom: 15px;
        text-shadow: 1px 1px 3px rgba(0,0,0,0.1);
      }

      h4 {
        font-size: 22px; /* Increased font size */
        color: #10b981;
        font-weight: 600;
        letter-spacing: 1px;
      }
    }

    /* Vertical Timeline Styles */
    .timeline {
      position: relative;
      margin: 50px 0;
      padding-left: 50px;
      border-left: 6px solid #d1d5db; /* Thicker timeline line */

      /* Decorative circle at the start */
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -8px;
        width: 20px;
        height: 20px;
        background: #3b82f6;
        border-radius: 50%;
        transform: translateX(-50%);
      }

      /* Decorative circle at the end */
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -8px;
        width: 20px;
        height: 20px;
        background: #10b981;
        border-radius: 50%;
        transform: translateX(-50%);
      }

      /* Responsive Layout */
      @media (max-width: 768px) {
        padding-left: 40px;
        border-left: 4px solid #d1d5db;

        &:before, &:after {
          left: -6px;
        }
      }

      .stage {
        position: relative;
        margin-bottom: 50px; /* Increased spacing */
        animation: ${slideIn} 0.6s ease-out;

        &:last-child {
          margin-bottom: 0;
        }

        .icon-container {
          position: absolute;
          left: -40px; /* Adjusted positioning */
          top: 0;
          background: #ffffff;
          border-radius: 50%;
          padding: 12px;
          border: 5px solid #d1d5db; /* Thicker border */
          transition: border-color 0.3s, background-color 0.3s;
          box-shadow: 0 0 12px rgba(0,0,0,0.1);
          display: flex;
          align-items: center;
          justify-content: center;

          &.completed {
            border-color: #10b981;
            background-color: #10b981;
            color: #ffffff;
          }

          &.active {
            border-color: #3b82f6;
            background-color: #3b82f6;
            color: #ffffff;
            animation: ${pulse} 2.5s infinite;
          }

          &.rejected {
            border-color: #f87171;
            background-color: #f87171;
            color: #ffffff;
          }

          svg {
            font-size: 24px; /* Increased icon size */
          }
        }

        .content {
          padding: 0 25px;

          .label {
            font-size: 20px; /* Increased font size */
            color: #374151;
            font-weight: 700;
            margin-bottom: 8px;
          }

          .description {
            font-size: 16px; /* Increased font size */
            color: #6b7280;
          }
        }

        /* Connector Lines */
        &:not(:last-child)::after {
          content: '';
          position: absolute;
          top: 35px; /* Adjust based on icon size */
          left: -3px;
          width: 6px;
          height: 20px;
          background: #d1d5db;
          z-index: -1;
        }
      }
    }

    /* Stage Content Styles */
    .stage-content {
      margin-top: 50px; /* Increased spacing */
      text-align: center;

      h5 {
        font-size: 28px; /* Increased font size */
        color: #10b981;
        font-weight: 700;
        margin-bottom: 25px;
      }

      p {
        font-size: 18px; /* Increased font size */
        color: #4b5563;
        line-height: 1.8;
        margin-bottom: 60px; /* Increased spacing */
        max-width: 800px; /* Increased max-width */
        margin-left: auto;
        margin-right: auto;
      }

      .download-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(135deg, #3b82f6, #2563eb);
        color: #ffffff;
        padding: 16px 60px; /* Increased padding */
        border-radius: 50px;
        text-decoration: none;
        font-size: 18px; /* Increased font size */
        font-weight: 700;
        transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
        box-shadow: 0 8px 24px rgba(59, 130, 246, 0.3);
        border: none;
        cursor: pointer;
        gap: 12px;

        &:hover {
          background: linear-gradient(135deg, #2563eb, #1e40af);
          transform: translateY(-4px);
          box-shadow: 0 10px 30px rgba(30, 64, 175, 0.3);
        }

        &:disabled {
          background: #93c5fd;
          cursor: not-allowed;
          box-shadow: none;
        }
      }

      .error-message {
        color: #b91c1c;
        background: #fee2e2;
        padding: 25px 30px;
        border-radius: 12px;
        margin-bottom: 25px;
        border: 1px solid #fca5a5;
        max-width: 650px; /* Increased max-width */
        margin: 25px auto;
        font-size: 16px; /* Increased font size */
      }

      .loading-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0; /* Increased spacing */
      }
    }

    /* Responsive Design */
    @media (max-width: 992px) {
      .admission-process-content {
        padding: 50px 60px; /* Adjusted padding */
      }

      .timeline {
        padding-left: 40px;
      }

      .stage .icon-container {
        left: -35px;
      }

      .stage-content h5 {
        font-size: 26px;
        margin-bottom: 20px;
      }

      .stage-content p {
        font-size: 17px;
        margin-bottom: 50px;
      }

      .download-button {
        padding: 14px 55px;
        font-size: 16px;
      }
    }

    @media (max-width: 768px) {
      .admission-process-content {
        padding: 40px 40px;
      }

      .timeline {
        padding-left: 35px;
      }

      .stage .icon-container {
        left: -30px;
      }

      .stage-content {
        padding: 0 20px;
      }

      .stage-content h5 {
        font-size: 24px;
        margin-bottom: 18px;
      }

      .stage-content p {
        font-size: 16px;
        margin-bottom: 45px;
      }

      .download-button {
        padding: 14px 50px;
        font-size: 16px;
      }
    }

    @media (max-width: 480px) {
      .admission-process-content {
        padding: 30px 25px;
      }

      .timeline {
        padding-left: 30px;
      }

      .stage .icon-container {
        left: -25px;
      }

      .stage-content {
        padding: 0 15px;
      }

      .stage-content h5 {
        font-size: 22px;
        margin-bottom: 15px;
      }

      .stage-content p {
        font-size: 15px;
        margin-bottom: 40px;
      }

      .download-button {
        padding: 12px 45px;
        font-size: 15px;
      }
    }
  `;

  // Sub-components for better organization
  const SuccessAlert = () => (
    <div className="alert-success" role="alert">
      <FaRegSmile />
      <span>You have successfully registered for the exam. Our team will contact you very soon.</span>
    </div>
  );

  const CongratsMessage = () => (
    <div className="message congrats" role="alert">
      <FaCheckCircle className="message-icon" />
      <div className="message-title">Congratulations!</div>
      <div className="message-text">
        Your application has been approved. You can now download your admit card.
      </div>
    </div>
  );

  const RejectedMessage = () => (
    <div className="message rejected" role="alert">
      <FaTimesCircle className="message-icon" />
      <div className="message-title">Application Rejected</div>
      <div className="message-text">
        We regret to inform you that your application has been rejected. Please contact our support team for more details.
      </div>
    </div>
  );

  const PendingMessage = () => (
    <div className="message pending" role="alert">
      <FaRegClock className="message-icon" />
      <div className="message-title">Application Is Pending</div>
      <div className="message-text">
        Your application is currently pending. Our team will review it and update you shortly.
      </div>
    </div>
  );

  const TimelineStage = ({ status, icon, label, description }) => {
    let statusClass = '';

    switch (status) {
      case 'completed':
        statusClass = 'completed';
        break;
      case 'active':
        statusClass = 'active';
        break;
      case 'rejected':
        statusClass = 'rejected';
        break;
      default:
        statusClass = 'pending';
    }

    return (
      <div className={`stage ${statusClass}`} aria-label={`${label} stage`}>
        <div className={`icon-container ${statusClass}`}>
          {icon}
        </div>
        <div className="content">
          <div className="label">{label}</div>
          {description && <div className="description">{description}</div>}
        </div>
      </div>
    );
  };

  function AdmissionProcess() {
    const location = useLocation();
    const [applicationStatus, setApplicationStatus] = useState('pending'); // 'pending', 'approved', 'rejected'
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    // Fetch registration data
    const fetchRegistrationData = useCallback(async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('You are not authenticated. Please log in.');
        return;
      }

      const url = 'https://api-pil.site/api/auth/user';

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          cache: 'no-store', // Prevents caching
        });

        if (!response.ok) {
          throw new Error('Failed to fetch registration data');
        }

        const data = await response.json();
        console.log('Fetched user data:', data); // Debugging line

        // Access applicationStatus from userData
        if (data.userData && data.userData.applicationStatus) {
          setApplicationStatus(data.userData.applicationStatus);
          console.log('Updated applicationStatus:', data.userData.applicationStatus);
        } else {
          console.warn('applicationStatus not found in userData.');
          setApplicationStatus('pending'); // Default to 'pending' if not found
        }

        // Show success alert if just registered
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get('register') === 'success') {
          setShowSuccessAlert(true);
        }
      } catch (err) {
        console.error('Error:', err);
        setError('There was an issue fetching your application status. Please try again later.');
      }
    }, [location.search]);

    useEffect(() => {
      fetchRegistrationData();
    }, [fetchRegistrationData]);

    // Function to handle admit card download
    const handleDownload = async () => {
      setLoading(true);
      setError(null);
      const token = localStorage.getItem('token');
      const url = 'https://api-pil.site/api/auth/admitCard'; // Ensure this endpoint is correct

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/pdf',
            Authorization: `Bearer ${token}`,
          },
          cache: 'no-store', // Prevents caching
        });

        if (!response.ok) {
          throw new Error('Failed to download the admit card');
        }

        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = 'admit-card.pdf';
        document.body.appendChild(a);
        a.click();
        a.remove();
      } catch (err) {
        console.error('Error:', err);
        setError('There was an issue downloading your admit card. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    // Determine the statuses for all stages
    const getTimelineStatus = () => {
      const statusMap = {
        pending: [
          { status: 'completed', label: 'Registration Submitted', icon: <FaRegSmile />, description: 'You have successfully registered for the exam.' },
          { status: 'active', label: 'Application Is Pending', icon: <FaRegClock />, description: 'Your application is under review.' },
          { status: 'pending', label: 'Awaiting Final Decision', icon: <FaRegClock />, description: 'Please wait for the final decision.' },
        ],
        approved: [
          { status: 'completed', label: 'Registration Submitted', icon: <FaRegSmile />, description: 'You have successfully registered for the exam.' },
          { status: 'completed', label: 'Application Approved', icon: <FaCheckCircle />, description: 'Your application has been approved.' },
          { status: 'completed', label: 'Admit Card Available', icon: <FaCheckCircle />, description: 'You can now download your admit card.' },
        ],
        rejected: [
          { status: 'completed', label: 'Registration Submitted', icon: <FaRegSmile />, description: 'You have successfully registered for the exam.' },
          { status: 'completed', label: 'Application Rejected', icon: <FaTimesCircle />, description: 'Your application has been rejected.' },
          { status: 'rejected', label: 'Contact Support', icon: <FaTimesCircle />, description: 'Please contact our support team for more details.' },
        ],
      };

      return statusMap[applicationStatus] || statusMap['pending'];
    };

    const timelineStages = getTimelineStatus();

    // Determine which message to show based on applicationStatus
    const renderStatusMessage = () => {
      switch (applicationStatus) {
        case 'pending':
          return <PendingMessage />;
        case 'approved':
          return <CongratsMessage />;
        case 'rejected':
          return <RejectedMessage />;
        default:
          return null;
      }
    };

    return (
      <>
        <LoggedNavbar />
        <Styles>
          <div className="admission-process-page">
            <Container>
              <Row className="justify-content-center">
                <Col lg={12} md={10}>
                  <div className="admission-process-content">
                    {showSuccessAlert && <SuccessAlert />}
                    {renderStatusMessage()}
                    <div className="heading">
                      <h2>Admission Process</h2>
                      <h4>Pioneer Institute of Learning, Kashmir</h4>
                    </div>
                    <div className="timeline" aria-label="Admission process timeline">
                      {timelineStages.map((stage, index) => (
                        <TimelineStage
                          key={index}
                          status={stage.status}
                          icon={stage.icon}
                          label={stage.label}
                          description={stage.description}
                        />
                      ))}
                    </div>
                    {applicationStatus === 'approved' && (
                      <div className="stage-content">
                        <h5>Application Approved!</h5>
                        <p>
                          Congratulations! Your application has been approved. You can now download your admit card below.
                        </p>
                        {error && (
                          <div className="error-message" role="alert">
                            {error}
                          </div>
                        )}
                        {loading ? (
                          <>
                            <div className="loading-spinner">
                              <Spinner animation="border" variant="primary" />
                            </div>
                            <button onClick={handleDownload} className="download-button" disabled>
                              Downloading...
                            </button>
                          </>
                        ) : (
                          <button onClick={handleDownload} className="download-button" disabled={loading}>
                            Download Admit Card
                          </button>
                        )}
                      </div>
                    )}
                    {applicationStatus === 'rejected' && (
                      <div className="stage-content">
                        <h5>Application Rejected</h5>
                        <p>
                          We regret to inform you that your application has been rejected. Please contact our support team for more details.
                        </p>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Styles>
      </>
    );
  }

  export default AdmissionProcess;
