// src/validations/registerFormValidation.js

import { z } from 'zod';

const registerSchema = z.object({
  class: z.string().nonempty({ message: 'Class is required' }),
  dated: z.string()
    .nonempty({ message: 'Dated is required' })
    .refine(date => !isNaN(Date.parse(date)), { message: 'Invalid date' }),
  studentName: z.string().nonempty({ message: 'Student name is required' }),
  dob_dd: z
    .string()
    .regex(/^\d{2}$/, { message: 'Day must be two digits' })
    .refine((val) => {
      const day = parseInt(val, 10);
      return day >= 1 && day <= 31;
    }, { message: 'Day must be between 01 and 31' }),
  dob_mm: z
    .string()
    .regex(/^\d{2}$/, { message: 'Month must be two digits' })
    .refine((val) => {
      const month = parseInt(val, 10);
      return month >= 1 && month <= 12;
    }, { message: 'Month must be between 01 and 12' }),
  dob_yyyy: z
    .string()
    .regex(/^\d{4}$/, { message: 'Year must be four digits' })
    .refine((val) => {
      const year = parseInt(val, 10);
      return year >= 1900 && year <= 2100;
    }, { message: 'Year must be between 1900 and 2100' }),
  dob_words: z.string().nonempty({ message: 'DOB in words is required' }),
  lastSchoolAttended: z.string().nonempty({ message: 'Last school attended is required' }),
  fatherName: z.string().nonempty({ message: "Father's name is required" }),
  fatherProfession: z.string().nonempty({ message: "Father's profession is required" }),
  motherName: z.string().nonempty({ message: "Mother's name is required" }),
  motherProfession: z.string().nonempty({ message: "Mother's profession is required" }),
  guardianName: z.string().optional(), // Optional
  guardianProfession: z.string().optional(), // Optional
  fatherContact: z
    .string()
    .min(10, { message: "Father's contact number must be at least 10 digits" })
    .max(15, { message: "Father's contact number must be at most 15 digits" })
    .regex(/^\d+$/, { message: "Father's contact number must contain only digits" }),
  motherContact: z
    .string()
    .min(10, { message: "Mother's contact number must be at least 10 digits" })
    .max(15, { message: "Mother's contact number must be at most 15 digits" })
    .regex(/^\d+$/, { message: "Mother's contact number must contain only digits" })
    .optional(),
  residence: z.string().nonempty({ message: 'Residence is required' }),
  village: z.string().nonempty({ message: 'Village/Town is required' }),
  tehsil: z.string().nonempty({ message: 'Tehsil is required' }),
  district: z.string().nonempty({ message: 'District is required' }),
  penNo: z.string().optional(),
  bloodGroup: z.string().optional(),
  sibling: z.string().nonempty({ message: 'Please specify if you have a sibling' }),
  siblingName: z.string().optional(), // Conditionally required
  siblingClass: z.string().optional(), // Conditionally required
  email: z.string().email({ message: 'Invalid email address' }),
  password: z.string()
    .min(6, { message: 'Password must be at least 6 characters long' }) // Changed from 8 to 6
    .regex(/[A-Z]/, { message: 'Password must contain at least one uppercase letter' })
    .regex(/[a-z]/, { message: 'Password must contain at least one lowercase letter' })
    .regex(/\d/, { message: 'Password must contain at least one number' }),
  studentPhoto: z
    .any()
    .optional()
    .refine((files) => files && files.length > 0, { message: 'Profile image is required' })
    .refine((files) => files && files[0] && files[0].size <= 3 * 1024 * 1024, { message: 'File size should not exceed 3MB.' })
    .refine(
      (files) => files && files[0] && ['image/jpeg', 'image/png', 'image/jpg'].includes(files[0].type),
      { message: 'Invalid file type. Only JPG, JPEG, and PNG are allowed.' }
    ),
})
.refine((data) => {
  // Password should not be the same as email
  if (data.password && data.email) {
    return data.password !== data.email;
  }
  return true;
}, {
  message: 'Password should not be the same as your email.',
  path: ['password'], // Assign error to the password field
})
.refine((data) => {
  if (data.sibling === 'yes') {
    return data.siblingName && data.siblingClass;
  }
  return true;
}, {
  message: 'Sibling name and class are required when sibling is yes',
  path: ['siblingName', 'siblingClass'],
});

export default registerSchema;
