import React, { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaTimes } from 'react-icons/fa';

// =====================
// Animations
// =====================
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const scaleUp = keyframes`
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1);
  }
`;

// =====================
// Styled Components
// =====================
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: ${fadeIn} 0.3s ease-out;
  padding: 10px; /* Added padding for smaller screens */
  box-sizing: border-box;
`;

const ModalContainer = styled.div`
  width: 85%;
  max-width: 800px;
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  display: flex;
  animation: ${scaleUp} 0.3s ease-out;
  transform: scale(1);
  transition: all 0.3s ease;

  @media (max-width: 1024px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    max-width: none;
    border-radius: 12px;
  }
`;

const ModalLeft = styled.div`
  flex: 1;
  background: url('/assets/images/about-2.png') no-repeat center center/cover;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Overlay for better text readability */
    border-radius: 12px 0 0 12px;

    @media (max-width: 768px) {
      border-radius: 12px 12px 0 0;
    }
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  color: #ffffff;
  z-index: 1;

  h4 {
    font-size: 2.2rem;
    font-weight: 600;
    margin-bottom: 15px;
    font-family: 'Poppins', sans-serif;
    z-index: 2;
    text-align: center;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
    z-index: 2;
    font-family: 'Roboto', sans-serif;
    max-width: 80%;
    text-align: center;
  }

  @media (max-width: 1024px) {
    padding: 35px;
  }

  @media (max-width: 768px) {
    padding: 25px;

    h4 {
      font-size: 1.8rem;
    }

    p {
      font-size: 1rem;
      max-width: 100%;
    }
  }
`;

const ModalRight = styled.div`
  flex: 1;
  padding: 50px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: #fdfdfd; /* Light background for a clean look */

  @media (max-width: 1024px) {
    padding: 45px 35px;
  }

  @media (max-width: 768px) {
    padding: 35px 25px;
  }
`;

const ModalTitle = styled.h5`
  font-size: 2rem;
  font-weight: 600;
  color: #333333;
  margin-bottom: 15px;
  text-align: center;
  font-family: 'Poppins', sans-serif;

  @media (max-width: 576px) {
    font-size: 1.8rem;
  }
`;

const ModalDescription = styled.p`
  font-size: 1rem;
  color: #555555;
  margin-bottom: 25px;
  line-height: 1.6;
  text-align: center;
  font-family: 'Roboto', sans-serif;

  @media (max-width: 576px) {
    font-size: 0.95rem;
  }
`;

const ApplyButton = styled.a`
  background: #02994c; /* Modern green color */
  color: #ffffff !important;
  border: none;
  padding: 12px 40px;
  font-size: 1rem;
  border-radius: 8px;
  transition: background 0.3s ease, transform 0.2s ease;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
  width: fit-content;

  &:hover {
    background: #45a049;
    transform: translateY(-2px);
  }

  @media (max-width: 576px) {
    font-size: 0.9rem;
    padding: 10px 35px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  font-size: 1.8rem;
  color: #333333;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #e53935; /* Red color on hover */
  }

  @media (max-width: 576px) {
    font-size: 1.5rem;
    top: 15px;
    right: 15px;
  }
`;

// =====================
// Modal Component
// =====================
const AdmissionModal = ({ show, handleClose }) => {
  const modalRef = useRef(null);

  // Auto-dismiss after 9 seconds
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        handleClose();
      }, 9000);
      return () => clearTimeout(timer);
    }
  }, [show, handleClose]);

  // Handle Escape key to close the modal
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };
    if (show) {
      window.addEventListener('keydown', handleEsc);
    }
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [show, handleClose]);

  // Focus trap
  useEffect(() => {
    const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal = modalRef.current;
    const firstFocusableElement = modal && modal.querySelectorAll(focusableElements)[0];
    const focusableContent = modal && modal.querySelectorAll(focusableElements);
    const lastFocusableElement = modal && focusableContent[focusableContent.length - 1];

    const handleTab = (e) => {
      if (e.key !== 'Tab') return;

      if (e.shiftKey) {
        // Shift + Tab
        if (document.activeElement === firstFocusableElement) {
          e.preventDefault();
          lastFocusableElement.focus();
        }
      } else {
        // Tab
        if (document.activeElement === lastFocusableElement) {
          e.preventDefault();
          firstFocusableElement.focus();
        }
      }
    };

    if (show) {
      document.addEventListener('keydown', handleTab);
      firstFocusableElement && firstFocusableElement.focus();
    }

    return () => {
      document.removeEventListener('keydown', handleTab);
    };
  }, [show]);

  if (!show) return null;

  // Handle click outside the modal to close
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  return (
    <ModalOverlay
      onClick={handleOverlayClick}
      aria-modal="true"
      role="dialog"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <ModalContainer ref={modalRef}>
        {/* Left Section with Background Image */}
        <ModalLeft>
          <h4>Welcome to Admissions</h4>
          <p>Join us and explore an exceptional learning journey.</p>
        </ModalLeft>

        {/* Right Section */}
        <ModalRight>
          <CloseButton onClick={handleClose} aria-label="Close Modal">
            <FaTimes />
          </CloseButton>
          <ModalTitle id="modal-title">Admissions Open for 2025</ModalTitle>
          <ModalDescription id="modal-description">
            Enroll now to secure your spot for the upcoming academic session. Join a
            community of excellence and innovation. Take the first step toward a brighter future!
          </ModalDescription>
          <ApplyButton
            href="https://pioneerinstitute.in/registration"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apply Now
          </ApplyButton>
        </ModalRight>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default AdmissionModal;
